import gql from 'graphql-tag';

const query = gql`
  query getInvoice($id: String!) {
    getInvoice(id: $id) {
      date
      id
      precision
      client {
        firstName
        lastName
        phone
      }
      total
      tax
      lineItems {
        name
        quantity
        price
        total
        tax
      }
    }
  }
`;
export default query;
