import {
  Checkbox,
  ChoiceGroup,
  Dropdown,
  FontWeights,
  IconButton,
  IDropdownOption,
  mergeStyleSets,
  PrimaryButton,
  Separator,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FieldError from '../components/FieldError';
import { TextArea } from '../components/TextArea';
import { IMAGE_HEIGHT, IMAGE_URL, IMAGE_WIDTH } from '../const/constants';
import {
  Anesthesia,
  ClientTXRequest as ClientTXRequestEnum,
  ClinicalCondition as ClinicalConditionEnum,
  NextTreatment as NextTreatmentEnum,
  Product,
  ProductType,
  Reaction,
  SpotInjectionInput,
  SpotType,
  Toggle,
  TreatmentInjection,
  TreatmentInjectionInput,
  TreatmentPlan as TreatmentPlanEnum,
  TreatmentProductInput,
} from '../generated/graphql';
import { dropdownProps, dropdownStyles } from '../styles/dropDownStyles';
import { InlineChoiceItemStyle, InlineChoiceStyle } from '../styles/InlineChoiceStyle';
import { theme } from '../styles/theme';
import { convertFieldErrorToString } from '../utils/convertToFormErrors';
import { compareProductVariant, PartialProductVariant } from '../utils/sortProduct';
import { ToggleArrayString } from '../utils/toogleArray';
import DrawCanvas from './DrawCanvas';

interface TreatmentProps {
  readOnly?: boolean;
  onSave: (treatment: TreatmentInjectionInput) => Promise<TreatmentInjection>;
  products: IDropdownOption<Product>[];
  treatment?: TreatmentInjection;
}

type OptionType =
  | 'ClientTXRequest'
  | 'ClinicalCondition'
  | 'TreatmentPlan'
  | 'NextTreatment'
  | 'Products';

const wrapperStyle = { display: 'flex', marginRight: theme.spacing.s1, width: '100%' };
const smallField = { field: { maxWidth: 50 } };
const inlineStyle = {
  ...smallField,
  wrapper: { ...wrapperStyle },
  fieldGroup: { marginLeft: theme.spacing.s1 },
};

const styles = mergeStyleSets({
  product: { fontSize: theme.fonts.large, fontWeight: FontWeights.semibold },
  error: { color: theme.semanticColors.errorText },
});

const spotsMap = new Map<ProductType, SpotType[]>([
  [
    ProductType.BOTOX,
    [
      SpotType.GLABELLA,
      SpotType.FOREHEAD,
      SpotType.CROWS,
      SpotType.EYEBROW,
      SpotType.CHIN,
      SpotType.BUNNY,
      SpotType.THICKJAWLINE,
      SpotType.NECK,
      SpotType.MESOTOX,
      SpotType.GUMMYSMILE,
      SpotType.LIPFLIP,
      SpotType.DAO
    ],
  ],
  [
    ProductType.FILLER,
    [
      SpotType.LIPS,
      SpotType.CHEEKS,
      SpotType.MARIONETTES,
      SpotType.EYE,
      SpotType.NSFOLDS,
      SpotType.CHIN,
      SpotType.JAWLINE,
      SpotType.SIDE,
    ],
  ],
]);

const TreatmentNote: React.FunctionComponent<TreatmentProps> = (props) => {
  const { t } = useTranslation();
  const { products, onSave } = props;
  const [treatment] = React.useState(props.treatment);

  const defaultValues = React.useMemo<Partial<TreatmentInjectionInput>>(() => {
    const baseValues: Partial<TreatmentInjectionInput> = {
      clientTXRequest: [],
      clientTXRequestNote: '',
      clinicalCondition: [],
      clinicalConditionNote: '',
      contraIndications: Toggle.NO,
      treatmentPlan: [],
      treatmentPlanNote: '',
      sideEffectsExplained: Toggle.NO,
      optimalResultExplained: Toggle.NO,
      questionsAnswered: Toggle.NO,
      reactions: [],
      reactionsNote: '',
      instructionsExplained: Toggle.NO,
      instructionsSent: Toggle.NO,
      invoiceSent: Toggle.NO,
      protocolAdjustedTreatment: Toggle.NO,
      followUp: Toggle.NO,
      nextTreatment: [],
      nextTreatmentNote: '',
      schema: '',
      ...(treatment || {}),
    };
    return baseValues;
  }, [treatment]);

  const options = React.useMemo<Record<OptionType, IDropdownOption[]>>(() => {
    const ClientTXRequest: IDropdownOption[] = Object.keys(ClientTXRequestEnum).map((req) => ({
      key: req,
      text: t(`option.clientTXRequest.${req}`),
    }));
    const ClinicalCondition: IDropdownOption[] = Object.keys(ClinicalConditionEnum).map((req) => ({
      key: req,
      text: t(`option.clinicalCondition.${req}`),
    }));
    const TreatmentPlan: IDropdownOption[] = Object.keys(TreatmentPlanEnum).map((req) => ({
      key: req,
      text: t(`option.treatmentPlan.${req}`),
    }));
    const NextTreatment: IDropdownOption[] = Object.keys(NextTreatmentEnum).map((req) => ({
      key: req,
      text: t(`option.nextTreatment.${req}`),
    }));

    const Products: Array<IDropdownOption<PartialProductVariant>> = products
      .flatMap((option) => {
        return (option.data?.productVariants?.length || 0) > 0
          ? option.data?.productVariants?.map((pv) => ({ ...pv, product: option.data as Product })) ||
          []
          : [{ product: option.data as Product }];
      })
      .sort(compareProductVariant)
      .map((variant: PartialProductVariant) => {
        const { product, id, name } = variant;
        return {
          key: `${product.id}_${id || ''}`,
          data: variant,
          text: `${
            product.name !== product.brand.name
              ? `${product.brand.name} ${product.name}`
              : product.name
          } ${name || ''}`,
        };
      });

    return {
      ClientTXRequest,
      ClinicalCondition,
      TreatmentPlan,
      NextTreatment,
      Products,
    };
  }, [t, products]);

  const ProductsMap = React.useMemo(
    () =>
      new Map<string, PartialProductVariant>(
        options.Products.map((x) => [
          x.key as string,
          {
            ...x.data,
            name: x.text,
          } || ({} as PartialProductVariant),
        ]),
      ),
    [options.Products],
  );

  const TreatmentNoteSchema = React.useMemo(() => {
    const isNotEmpty = t('constraints:isNotEmpty');
    return yup.object().shape<TreatmentInjectionInput>({
      clientTXRequestNote: yup.string(),
      clientTXRequest: yup.array<ClientTXRequestEnum>(),
      clinicalCondition: yup.array<ClinicalConditionEnum>(),
      clinicalConditionNote: yup.string(),
      treatmentPlan: yup.array<TreatmentPlanEnum>(),
      treatmentPlanNote: yup.string(),
      contraIndications: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      sideEffectsExplained: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      optimalResultExplained: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      questionsAnswered: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      reactions: yup.array<Reaction>(),
      reactionsNote: yup.string(),
      instructionsExplained: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      instructionsSent: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      invoiceSent: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      protocolAdjustedTreatment: yup
        .mixed<Toggle>()
        .oneOf(Object.values(Toggle))
        .required(isNotEmpty),
      followUp: yup.mixed<Toggle>().oneOf(Object.values(Toggle)).required(isNotEmpty),
      nextTreatment: yup.array<NextTreatmentEnum>(),
      nextTreatmentNote: yup.string(),
      schema: yup.string(),
      schemaBase: yup.string(),
      treatmentProducts: yup.array<TreatmentProductInput>().of(
        yup.object().shape<TreatmentProductInput>({
          units: yup.number().required().min(0),
          productId: yup.mixed().required(),
          productVariantId: yup.mixed(),
          note: yup.string().nullable(),
          spots: yup.array<SpotInjectionInput>().min(1),
        }),
      ),
    });
  }, [t]);

  const {
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { isSubmitting, errors },
    control,
    register,
  } = useForm<TreatmentInjectionInput>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(TreatmentNoteSchema),
    criteriaMode: 'all',
  });

  const {
    fields: treatmentProductFields,
    append: appendUsage,
    remove: removeUsage,
  } = useFieldArray({
    control,
    name: 'treatmentProducts',
  });

  const watchTreatmentProducts = watch('treatmentProducts');

  React.useEffect(() => {
    watchTreatmentProducts?.forEach((product, index) => {
      const id = `${product.productId}_${product.productVariantId || ''}`;
      const productVariant = ProductsMap.get(id);
      if (productVariant?.product.productType === ProductType.BOTOX) {
        const totalUnits = product.spots?.reduce((pv, cv) => {
          return pv + cv.units;
        }, 0);
        if (product.units !== totalUnits) {
          setValue(
            `treatmentProducts.${index}.units` as 'treatmentProducts.0.units',
            totalUnits || 0,
            {
              shouldDirty: false,
              shouldValidate: false,
            },
          );
        }
      }
    });
  }, [ProductsMap, setValue, JSON.stringify(watchTreatmentProducts)]);

  const onSelectProduct = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<PartialProductVariant>) => {
      if (option?.data === undefined) return;
      clearErrors('treatmentProducts');
      const {
        product: { id: productId },
      } = option.data;
      const productVariantId = option.data.id;
      if (
        treatmentProductFields.findIndex(
          (item) => item.productId === productId && item.productVariantId === productVariantId,
        ) === -1
      ) {
        appendUsage({
          productId,
          productVariantId,
          units: 0,
          spots: [],
        });
      }
    },
    [treatmentProductFields, appendUsage, clearErrors],
  );

  // @ts-ignore
  return (
    <div style={{ userSelect: 'none' }}>
      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        <StackItem>
          <Stack horizontal horizontalAlign="end">
            <PrimaryButton
              text={t('button.save')}
              primary={true}
              disabled={isSubmitting || props.readOnly}
              onClick={async (): Promise<void> => {
                return handleSubmit(onSave)();
              }}>
              {t('button.save')}
            </PrimaryButton>
          </Stack>
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="schema"
            render={({ field: { onChange, value } }) => (
              <DrawCanvas
                disabled={isSubmitting}
                value={value}
                onChange={onChange}
                color={theme.palette.themePrimary}
                imageURL={IMAGE_URL}
                imageWidth={IMAGE_WIDTH}
                imageHeight={IMAGE_HEIGHT}
              />
            )}
          />
        </StackItem>
        <Separator>{t('label.preTreatment.title')}</Separator>
        <StackItem>
          <Controller
            control={control}
            name="clientTXRequest"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                {...dropdownProps}
                styles={dropdownStyles}
                options={options.ClientTXRequest}
                label={t('label.clientTXRequest')}
                multiSelect
                disabled={isSubmitting}
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(ToggleArrayString(value, newValue?.key as string));
                }}
                selectedKeys={value}
                // @ts-ignore
                errorMessage={convertFieldErrorToString(errors.clientTXRequest)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="clientTXRequestNote"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                disabled={isSubmitting}
                onBlur={onBlur}
                value={value}
                onChange={(_, v) => onChange(v)}
                errorMessage={convertFieldErrorToString(errors.clientTXRequestNote)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="clinicalCondition"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                {...dropdownProps}
                styles={dropdownStyles}
                options={options.ClinicalCondition}
                label={t('label.clinicalCondition')}
                multiSelect
                disabled={isSubmitting}
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(ToggleArrayString(value, newValue?.key as string));
                }}
                selectedKeys={value}
                // @ts-ignore
                errorMessage={convertFieldErrorToString(errors.clinicalCondition)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="clinicalConditionNote"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                disabled={isSubmitting}
                onBlur={onBlur}
                value={value}
                onChange={(_, v) => onChange(v)}
                errorMessage={convertFieldErrorToString(errors.clinicalConditionNote)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="contraIndications"
            render={({ field: { onChange, value } }) => (
              <>
                <ChoiceGroup
                  label={t('label.contraIndications')}
                  styles={InlineChoiceStyle}
                  selectedKey={value}
                  disabled={isSubmitting}
                  onChange={(_, option) => {
                    clearErrors('contraIndications');
                    onChange(option?.key);
                  }}
                  options={[
                    { key: Toggle.YES, text: t('option.yes'), styles: InlineChoiceItemStyle },
                    { key: Toggle.NO, text: t('option.no'), styles: InlineChoiceItemStyle },
                  ]}
                />
                {errors.contraIndications && (
                  <FieldError>{convertFieldErrorToString(errors.contraIndications)}</FieldError>
                )}
              </>
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="treatmentPlan"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                {...dropdownProps}
                styles={dropdownStyles}
                options={options.TreatmentPlan}
                label={t('label.treatmentPlan')}
                multiSelect
                disabled={isSubmitting}
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(ToggleArrayString(value, newValue?.key as string));
                }}
                selectedKeys={value}
                // @ts-ignore
                errorMessage={convertFieldErrorToString(errors.treatmentPlan)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="treatmentPlanNote"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                disabled={isSubmitting}
                onBlur={onBlur}
                value={value}
                onChange={(_, v) => onChange(v)}
                errorMessage={convertFieldErrorToString(errors.treatmentPlanNote)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: theme.spacing.s1 }} horizontal wrap>
            <Controller
              control={control}
              name="sideEffectsExplained"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.preTreatment.sideEffectsExplained')}
                  indeterminate={false}
                />
              )}
            />
            <Controller
              control={control}
              name="optimalResultExplained"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.preTreatment.optimalResultExplained')}
                  indeterminate={false}
                />
              )}
            />
            <Controller
              control={control}
              name="questionsAnswered"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.preTreatment.questionsAnswered')}
                  indeterminate={false}
                />
              )}
            />
          </Stack>
        </StackItem>
        <StackItem>
          <Separator>{t('label.treatment.title')}</Separator>
        </StackItem>
        <StackItem>
          <Dropdown
            {...dropdownProps}
            styles={dropdownStyles}
            disabled={isSubmitting}
            options={options.Products}
            label={t('label.product')}
            selectedKey=""
            onChange={onSelectProduct}
            onRenderOption={(item) => <span
              style={item?.data?.product.priority ? { fontWeight: 'bold' } : {}}>{item?.text}</span>}
          />
        </StackItem>
        {errors.treatmentProducts && !(errors.treatmentProducts instanceof Array) && (
          <FieldError>{t('constraints:isNotEmpty')}</FieldError>
        )}
        {treatmentProductFields.map((field, index) => {
          const id = `${field.productId}_${field.productVariantId || ''}`;
          const productVariant = ProductsMap.get(id);
          if (!productVariant) return <StackItem key={id}>UNKNOWN / Contact support</StackItem>;
          const { product, name } = productVariant;
          const isBotox = product.productType === ProductType.BOTOX;
          const productSpots = spotsMap.get(product.productType);
          register(`treatmentProducts.${index}.productId` as 'treatmentProducts.0');
          register(`treatmentProducts.${index}.productVariantId` as 'treatmentProducts.0');
          return (
            <StackItem key={id}>
              <Separator>{t(`product.${product.productType}`)}</Separator>
              <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                <StackItem>
                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{ childrenGap: theme.spacing.s1 }}>
                    <StackItem>
                      <Controller
                        control={control}
                        name={`treatmentProducts.${index}.units` as 'treatmentProducts.0.units'}
                        defaultValue={field.units}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            styles={smallField}
                            readOnly={isBotox}
                            disabled={isSubmitting}
                            value={(value || '') as string}
                            onChange={(_, unitsIn) => {
                              if (!unitsIn || /^\d*\.?\d{0,2}$/.test(unitsIn)) {
                                onChange(unitsIn || '');
                              }
                            }}
                          />
                        )}
                      />
                    </StackItem>
                    <StackItem
                      className={[
                        styles.product,
                        errors.treatmentProducts && errors.treatmentProducts[index]
                          ? styles.error
                          : '',
                      ].join(' ')}
                      grow
                      align="center">
                      {name || 'UNKNOWN'}
                    </StackItem>
                    <StackItem>
                      <IconButton
                        disabled={isSubmitting}
                        iconProps={{ iconName: 'Delete' }}
                        onClick={(): unknown => removeUsage(index)}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>
                {productSpots && (
                  <StackItem>
                    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }} wrap>
                      <Controller
                        control={control}
                        name={`treatmentProducts.${index}.spots` as 'treatmentProducts.0.spots'}
                        defaultValue={field.spots}
                        render={({ field: { onChange, value } }) => (
                          <>
                            {productSpots.map((spot) => {
                              const spotIndex = (value || []).findIndex((v) => v.spot === spot);
                              return isBotox ? (
                                <TextField
                                  key={spot}
                                  styles={inlineStyle}
                                  disabled={isSubmitting}
                                  value={((value || [])[spotIndex]?.units || '') as string}
                                  onChange={(_, unitsIn) => {
                                    let newValues: SpotInjectionInput[] = [];
                                    if (!unitsIn || unitsIn.match(/^[0-9]+$/)) {
                                      const units = Number(unitsIn || '');
                                      if (spotIndex === -1) {
                                        newValues = [...(value || []), { spot, units }];
                                      } else {
                                        if (units === 0) {
                                          newValues = (value || []).filter((s) => s.spot !== spot);
                                        } else {
                                          newValues = [...(value || [])];
                                          newValues[spotIndex].units = units;
                                        }
                                      }
                                      onChange(newValues);
                                    }
                                  }}
                                  label={t(`label.injectionNeuromodulator.${spot}`)}
                                />
                              ) : (
                                <Checkbox
                                  key={spot}
                                  disabled={isSubmitting}
                                  label={t(`label.injectionFiller.${spot}`)}
                                  checked={spotIndex !== -1}
                                  onChange={(_, _checked) => {
                                    onChange(
                                      spotIndex === -1
                                        ? [...(value || []), { spot, units: 1 }]
                                        : (value || []).filter((v) => v.spot !== spot),
                                    );
                                  }}
                                />
                              );
                            })}
                          </>
                        )}
                      />
                    </Stack>
                  </StackItem>
                )}
                {product.productType === ProductType.FILLER && (
                  <StackItem>
                    <Separator>{t('label.anesthesia.title')}</Separator>
                    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }} wrap>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }} wrap>
                          <Controller
                            control={control}
                            name={
                              `treatmentProducts.${index}.anesthesia` as 'treatmentProducts.0.anesthesia'
                            }
                            defaultValue={field.anesthesia}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Checkbox
                                  disabled={isSubmitting}
                                  label={t('label.anesthesia.ICEPACK')}
                                  checked={(value || []).includes(Anesthesia.ICEPACK)}
                                  onChange={(_, _checked) =>
                                    onChange(ToggleArrayString(value || [], Anesthesia.ICEPACK))
                                  }
                                />
                                <Checkbox
                                  disabled={isSubmitting}
                                  label={t('label.anesthesia.LIDOCAINE')}
                                  checked={(value || []).includes(Anesthesia.LIDOCAINE)}
                                  onChange={(_, _checked) =>
                                    onChange(ToggleArrayString(value || [], Anesthesia.LIDOCAINE))
                                  }
                                />
                                <Checkbox
                                  disabled={isSubmitting}
                                  label={t('label.anesthesia.STANHEXIDINE')}
                                  checked={(value || []).includes(Anesthesia.STANHEXIDINE)}
                                  onChange={(_, _checked) =>
                                    onChange(ToggleArrayString(value || [], Anesthesia.STANHEXIDINE))
                                  }
                                />
                              </>
                            )}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </StackItem>
                )}
                <StackItem>
                  <Controller
                    control={control}
                    name={`treatmentProducts.${index}.note` as 'treatmentProducts.0.note'}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextArea
                        disabled={isSubmitting}
                        onBlur={onBlur}
                        value={value || ''}
                        onChange={(_, v) => onChange(v)}
                        errorMessage={
                          errors.treatmentProducts &&
                          convertFieldErrorToString(errors.treatmentProducts[index]?.note)
                        }
                      />
                    )}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          );
        })}
        <StackItem>
          <Separator>{t('label.reaction.title')}</Separator>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }} wrap>
            <Controller
              control={control}
              name="reactions"
              render={({ field: { onChange, value } }) => (
                <>
                  {Object.keys(Reaction).map((v) => (
                    <Checkbox
                      disabled={isSubmitting}
                      key={v}
                      label={t(`label.reaction.${v}`)}
                      checked={value?.includes(v as Reaction)}
                      onChange={() => onChange(ToggleArrayString(value, v))}
                    />
                  ))}
                </>
              )}
            />
          </Stack>
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="reactionsNote"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                disabled={isSubmitting}
                onBlur={onBlur}
                value={value}
                onChange={(_, v) => onChange(v)}
                errorMessage={convertFieldErrorToString(errors.reactionsNote)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Separator>{t('label.postTreatment.title')}</Separator>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }} wrap>
            <Controller
              control={control}
              name="instructionsExplained"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.postTreatment.instructionsExplained')}
                  indeterminate={false}
                />
              )}
            />
            <Controller
              control={control}
              name="instructionsSent"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.postTreatment.instructionsSent')}
                  indeterminate={false}
                />
              )}
            />
            <Controller
              control={control}
              name="invoiceSent"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.postTreatment.invoiceSent')}
                  indeterminate={false}
                />
              )}
            />
            <Controller
              control={control}
              name="protocolAdjustedTreatment"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.postTreatment.protocolAdjustedTreatment')}
                  indeterminate={false}
                />
              )}
            />
            <Controller
              control={control}
              name="followUp"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  disabled={isSubmitting}
                  checked={value === Toggle.YES}
                  onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                  label={t('label.postTreatment.followUp')}
                  indeterminate={false}
                />
              )}
            />
          </Stack>
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="nextTreatment"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                {...dropdownProps}
                styles={dropdownStyles}
                options={options.NextTreatment}
                label={t('label.nextTreatment')}
                multiSelect
                disabled={isSubmitting}
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(ToggleArrayString(value, newValue?.key as string));
                }}
                selectedKeys={value}
                // @ts-ignore
                errorMessage={convertFieldErrorToString(errors.nextTreatment)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Controller
            control={control}
            name="nextTreatmentNote"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                multiline
                disabled={isSubmitting}
                onBlur={onBlur}
                value={value}
                onChange={(_, v) => onChange(v)}
                errorMessage={convertFieldErrorToString(errors.nextTreatmentNote)}
              />
            )}
          />
        </StackItem>
        <StackItem>
          <Stack horizontal horizontalAlign="end">
            <PrimaryButton
              text={t('button.save')}
              primary={true}
              disabled={isSubmitting || props.readOnly}
              onClick={async (): Promise<void> => {
                console.log({ errors });

                return handleSubmit(onSave)();
              }}>
              {t('button.save')}
            </PrimaryButton>
          </Stack>
        </StackItem>
      </Stack>
    </div>
  );
};

export default TreatmentNote;
